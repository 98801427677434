import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.png"
import AniLink from "gatsby-plugin-transition-link/AniLink"



const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <AniLink
                                paintDrip
                                hex="#ff5d22"
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </AniLink>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/industries"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            <i className='bx bx-chevron-right'></i>INDUSTRIES
                                        </AniLink>

                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    IT Services
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/seo-agency"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    SEO Agency
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/data-science-ml-company"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Data Science ML Company
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/data-analytics-ai-startup"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Data Analytics & AI Startup
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/digital-marketing-agency"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Digital Marketing Agency
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/data-science-online-courses"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Data Science Online Courses
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/big-data-analysis-startup"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Big Data Analysis Startup
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/data-analytics-ml-consulting"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Data Analytics ML Consulting
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/machine-learning-ai-solutions"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Machine Learning AI Solutions
                                                </AniLink>
                                            </li>
                                        </ul> */}
                                    </li>

                                    {/* <li className="nav-item">
                                        <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="#"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            <i className='bx bx-chevron-right'></i>TECHNOLOGIES
                                        </AniLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/about-us"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    About Us
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/history"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    History
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/testimonials"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Testimonials
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/team"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Team One
                                                </AniLink>
                                            </li>
                                        </ul>
                                    </li> */}


                                    <li className="nav-item">
                                        <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/capabilities/allservices"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            <i className='bx bx-chevron-right'></i>SERVICES

                                        </AniLink>

                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/coming-soon"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    INSIGHTS
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/case-studies-details"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Case Studies Details
                                                </AniLink>
                                            </li>
                                        </ul> */}
                                    </li>
                                    
                                    <li className="nav-item">
                                    <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/sme"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                         >
                                             <i className='bx bx-chevron-right'></i>INSIGHTS
                                        </AniLink>

                                    </li>

                                    <li className="nav-item">
                                    <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/consultation"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                         >
                                             <i className='bx bx-chevron-right'></i>CONTACT US
                                        </AniLink>

                                    </li>

                                    <li className="nav-item">
                                        
                                        {/* <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/careers/jobs"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            <i className='bx bx-chevron-right'></i>CAREERS
                                        </AniLink> */}

                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/coming-soon"
                                                    activeClassName="active"
                                                    onClick={e => e.preventDefault()}
                                                    className="nav-link"
                                                >
                                                    Courses <i className='bx bx-chevron-right'></i>
                                                </AniLink>

                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <AniLink
                                                            paintDrip
                                                            hex="#ff5d22"
                                                            to="/courses"
                                                            activeClassName="active"
                                                            onClick={() => setCollapsed(true)}
                                                            className="nav-link"
                                                        >
                                                            Courses
                                                        </AniLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <AniLink
                                                            paintDrip
                                                            hex="#ff5d22"
                                                            to="/course-details"
                                                            activeClassName="active"
                                                            onClick={() => setCollapsed(true)}
                                                            className="nav-link"
                                                        >
                                                            Course Details
                                                        </AniLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="#"
                                                    activeClassName="active"
                                                    onClick={e => e.preventDefault()}
                                                    className="nav-link"
                                                >
                                                    Events <i className='bx bx-chevron-right'></i>
                                                </AniLink>

                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <AniLink
                                                            paintDrip
                                                            hex="#ff5d22"
                                                            to="/events"
                                                            activeClassName="active"
                                                            onClick={() => setCollapsed(true)}
                                                            className="nav-link"
                                                        >
                                                            Events
                                                        </AniLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <AniLink
                                                            paintDrip
                                                            hex="#ff5d22"
                                                            to="/event-details"
                                                            activeClassName="active"
                                                            onClick={() => setCollapsed(true)}
                                                            className="nav-link"
                                                        >
                                                            Event Details
                                                        </AniLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/contact"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    CONTACT US
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/gallery"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Gallery
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/faq"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    FAQ
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/coming-soon"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Coming Soon
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/membership-levels"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Membership Levels
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/profile-authentication"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Login/Register
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/privacy-policy"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Privacy Policy
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/terms-of-service"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Terms of Service
                                                </AniLink>
                                            </li>
                                        </ul> */}
                                    </li>

                                    <li className="nav-item">
                                        {/* <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/contact"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                             <i className='bx bx-chevron-right'></i>CONTACT US
                                        </AniLink> */}

                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/services"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Services
                                                </AniLink>
                                            </li>

                                            <li className="nav-item">
                                                <AniLink
                                                    paintDrip
                                                    hex="#ff5d22"
                                                    to="/service-details"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Service Details
                                                </AniLink>
                                            </li>
                                        </ul> */}
                                    </li>

                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        {/* <form className="search-box">
                                            <input type="text" className="input-search" placeholder="Search for anything" />
                                            <button type="submit">
                                                <i className="flaticon-loupe"></i>
                                            </button>
                                        </form> */}
                                    </div>

                                    <div className="option-item">
                                        <AniLink
                                            paintDrip
                                            hex="#ff5d22"
                                            to="/consultation"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                         >
                                            <i className="flaticon-right"></i>Book Consultation<span></span>
                                        </AniLink>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;